<template>
  <a-modal
    :visible.sync="show"
    title="批量设置当前库存"
    width="30%"
    @ok="handleOk"
    @cancel="$emit('update:show', false)"
  >
    <div class="modal-search-bar">
      <div class="flex items-center">
        <span>当前库存</span>
        <span class="ml-3">
          <a-input v-model="totalStock" placeholder="请输入当前库存" />
        </span>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
    },
    skuId: {
      type: String,
    },
  },
  data() {
    return {
      totalStock: undefined,
    }
  },
  methods: {
    handleOk() {
      this.$emit("updateCurrentData", this.totalStock)
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>