<template>
  <a-modal
    :visible.sync="show"
    title="批量设置商品库存"
    width="30%"
    @ok="handleOk"
    @cancel="$emit('update:show', false)"
  >
    <div class="modal-search-bar">
      <div class="flex items-center">
        <span>商品库存</span>
        <span class="ml-3">
          <a-input v-model="totalStock" placeholder="请输入商品库存" />
        </span>
      </div>
    </div>
  </a-modal>
</template>

<script>
// import { getCurrentStockBySku, editGoodsCurrentStock } from "@/api/warehouse"

export default {
  props: {
    show: {
      type: Boolean,
    },
    skuId: {
      type: String,
    },
  },
  data() {
    return {
      totalStock: undefined,
    }
  },
  methods: {
    handleOk() {
      this.$emit("updateShopData", this.totalStock)
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>