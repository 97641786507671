<template>
  <div>
    <div class="search-bar">
      <a-form-model
        :labelCol="{ span: 8 }"
        :wrapperCol="{ span: 16 }"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <a-col :span="6">
            <a-form-model-item label="产品属性" name="adcode" prop="type">
              <a-select v-model="searchForm.type" placeholder="产品属性"
                  @change="handlerSearch">
                <a-select-option value>全部</a-select-option>
                <a-select-option
                  v-for="item in Object.keys(goodsType)"
                  :value="item"
                  :key="item"
                >{{goodsType[item]}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="商品名称" name="type" prop="goods_name">
              <a-input allowClear v-model="searchForm.goods_name" placeholder="请输入商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item>
              <a-button class="mr-2" @click="handlerResetForm">重置</a-button>
              <a-button type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <base-table
      ref="tableRef"
      rowKey="sku_id"
      :columnsData="columns"
      :tableData="tableData"
      :total="total"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      >
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">[{{storehouseName}}]库存管理</h4>

          <div>
            <!-- <a-button class="ml-3" type="primary" html-type="submit"
              :disabled="!selectedRowKeys || !selectedRowKeys.length"
              @click="hanlderShopStock"
            >批量修改库存</a-button> -->
            <a-button class="ml-3" type="primary" html-type="submit"
              :disabled="!selectedRowKeys || !selectedRowKeys.length"
              @click="hanlderCurrentStock"
            >批量修改当前库存</a-button>
          </div>
        </div>
      </template>
      <!-- <template #goods_stock="{record}">
        <a-input v-model="record.goods_stock" @blur="hanlderGoodsStockOnBlur(record)" />
      </template> -->
      <template #current_stock="{record}">
        <a-input v-model="record.current_stock" @blur="hanlderCurrentStockOnBlur(record)" />
      </template>
    </base-table>

    <BatchSettingShop v-if="isShowShop" :show.sync="isShowShop" @updateShopData="updateShopData" />
    <BatchSettingCurrent
      v-if="isShowCurrent"
      :show.sync="isShowCurrent"
      @updateCurrentData="updateCurrentData"
    />
  </div>
</template>

<script>
import BatchSettingShop from "./components/batchSettingShop"
import BatchSettingCurrent from "./components/batchSettingCurrent"
import { goodsType, formatGoodsType } from "@/utils/type"
import {
  getWarehouseGoodsSkuList,
  // editWarehouseGoodsSkuStock,
  editWarehouseGoodsCurrentSkuStock,
} from "../../../api/warehouse"
import { rowSpan } from "@/utils/index"

export default {
  components: {
    BatchSettingShop,
    BatchSettingCurrent,
  },
  data() {
    return {
      goodsType,
      columns: [
        {
          title: "产品属性",
          align: "center",
          dataIndex: "goods_type",
          width: 80,
          customRender: (text, row) => {
            return {
              children: formatGoodsType(text),
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "商品名称",
          align: "center",
          dataIndex: "goods_name",
          width: "40%",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.goods_nameRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "口味",
          align: "center",
          dataIndex: "flavor_name",
          width: "30%",
        },
        {
          title: "尺寸",
          align: "center",
          dataIndex: "specification_name",
          width: "30%",
        },
        // {
        //   title: "商品库存",
        //   align: "center",
        //   dataIndex: "goods_stock",
        //   width: 120,
        //   slots: {
        //     customRender: "goods_stock",
        //   },
        // },
        {
          title: "当前库存",
          align: "center",
          dataIndex: "current_stock",
          width: 120,
          slots: {
            customRender: "current_stock",
          },
        },
      ],
      chooseArr: [],
      storehouseName: "",
      isShowShop: false,
      isShowCurrent: false,
      tableData: [],
      shopList: [],
      total: 0,
      searchForm: {
        virtual_warehouse_id: "",
        goods_name: "",
        type: "",
        goods_number: "",
        page: 1,
        page_count: 10,
      },
      selectedRowKeys: [],
    }
  },
  mounted() {
    this.searchForm.virtual_warehouse_id = this.$route.query.id
    this.storehouseName = this.$route.query.name
    this.initData()
  },
  methods: {
    async initData() {
      const { code, data } = await getWarehouseGoodsSkuList(this.searchForm)
      if (code == 0) {
        let arr = data.list
        arr = rowSpan(arr, "goods_number")
        this.tableData = rowSpan(arr, "goods_name")

        this.total = data.total_count
      }
    },
    handlerSearch(){
      this.searchForm.page = 1
      this.initData()
    },
    handlerResetForm() {
      this.$refs.searchRef.resetFields()
      this.searchForm.page = 1
      this.initData()
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    // 以下修改商品库存
    // hanlderShopStock() {
    //   if (this.selectedRowKeys.length == 0) {
    //     this.$message.warning("请先勾选需要批量的条目")
    //   } else {
    //     this.isShowShop = true
    //   }
    // },
    // hanlderGoodsStockOnBlur(e) {
    //   this.updateShopData(e, "tableSource")
    // },
    // async updateShopData(e, source) {
    //   let params = {}
    //   if (source == "tableSource") {
    //     params = {
    //       virtual_warehouse_id: this.searchForm.virtual_warehouse_id,
    //       sku_ids: e.sku_id,
    //       goods_stock: e.goods_stock,
    //     }
    //   } else {
    //     params = {
    //       virtual_warehouse_id: this.searchForm.virtual_warehouse_id,
    //       sku_ids: this.selectedRowKeys.toString(),
    //       goods_stock: e,
    //     }
    //   }
    //   const { code } = await editWarehouseGoodsSkuStock(params)
    //   if (code == 0) {
    //     this.$message.success("修改成功")
    //     this.isShowShop = false
    //     this.initData()
    //     this.selectedRowKeys = []
    //   }
    // },

    // 以下修改当前库存
    hanlderCurrentStock() {
      if (this.selectedRowKeys.length == 0) {
        this.$message.warning("请先勾选需要批量的条目")
      } else {
        this.isShowCurrent = true
      }
    },
    hanlderCurrentStockOnBlur(e) {
      this.updateCurrentData(e, "tableSource")
    },
    async updateCurrentData(e, source) {
      let params = {}
      if (source == "tableSource") {
        params = {
          virtual_warehouse_id: this.searchForm.virtual_warehouse_id,
          sku_ids: e.sku_id,
          current_stock: e.current_stock,
        }
      } else {
        params = {
          virtual_warehouse_id: this.searchForm.virtual_warehouse_id,
          sku_ids: this.selectedRowKeys.toString(),
          current_stock: e,
        }
      }

      const { code } = await editWarehouseGoodsCurrentSkuStock(params)
      if (code == 0) {
        this.$message.success("修改成功")
        this.isShowCurrent = false
        this.initData()
        this.selectedRowKeys = []
      }
    },
  },
}
</script>

<style>
</style>